<template>
  <div class=" container-fluid crypto-wrapper p-5">
    <section class="row">
      <h1 class="text-white">My referals</h1>
    </section>
    <section class="row mt-4">
      <div class="col-xl-7 mb-4 mb-lg-0">
        <div class="row">
          <div class="col-lg-6">
            <div class="card p-3">
              <div class="card-header text-center">
                <h4 class="text-second text-start">Total number of partners</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-1">
                    <h1>0</h1>
                  </div>
                  <div class="col-10 d-flex align-items-start chart-coll">
                    <apexchart
                      type="line"
                      height="50px"
                      ref="chart"
                      :options="chartOptions"
                      :series="series"
                    />
                  </div>
                  <span class="active-referals">0 Active</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card p-3">
              <div class="card-header text-center">
                <h4 class="text-second text-start">First-line partners</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-1">
                    <h1>0</h1>
                  </div>
                  <div class="col-10 d-flex align-items-start chart-coll">
                    <apexchart
                      type="line"
                      height="50px"
                      ref="chart"
                      :options="chartOptions"
                      :series="series"
                    />
                  </div>
                  <span class="active-referals">0 Active</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-3 mt-3">
          <div class="card-header text-center">
            <div class="row justify-content-between">
              <div class="col-9 text-start text-second">
                <h4>Wallet</h4>
              </div>
              <div
                class="col-3 button d-flex align-items-baseline justify-content-end"
              >
                <button>View Report</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <h4>US Dollar <span>5, 245.36</span></h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <img src="~@assets/img/Arrow Up.svg" alt="row" />
              </div>
              <div class="ms-2">
                <p class="green_component"><span>2.1%</span> vs last week</p>
              </div>
            </div>
            <h3 class="mt-4">Sales from 1-12 Dec, 2020</h3>
            <div class="row">
              <apexchart
                type="bar"
                height="150px"
                ref="chart"
                :options="chartOptionsBar"
                :series="series"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 mb-4 mb-md-0">
        <div class="card p-3">
          <div class="card-header text-center">
            <div class="row">
              <div class="col-4 text-start text-second">
                <h5 style="font-size:30px; font-weight:600" class="text-white">
                  Title
                </h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-3">
                <p>Name</p>
              </div>
              <div class="col-2 text-center">
                <p>Prestige</p>
              </div>
              <div class="col-3 text-center">
                <p style="color: #f1f601!important">Prestige1</p>
              </div>
              <div class="col-4 "></div>
            </div>
            <div class="row card-info py-3">
              <div class="col-3">
                <div class="row info__text">
                  <div class="col-3 avatar"></div>
                  <div
                    class="col-9 d-flex align-items-center justify-content-center"
                  >
                    <p>XMR/BNB</p>
                  </div>
                </div>
              </div>
              <div
                class="col-2 info__text d-flex align-items-center justify-content-center"
              >
                <p>$ 20.00</p>
              </div>
              <div
                class="col-3 info__text2 d-flex align-items-center justify-content-center"
              >
                <p>$5.00</p>
              </div>
              <div
                class="col-4 button d-flex align-items-baseline justify-content-end"
              >
                <button class="me-3">View</button>
                <button>Close</button>
              </div>
            </div>
            <div class="row card-info py-3">
              <div class="col-3">
                <div class="row info__text">
                  <div class="col-3 avatar"></div>
                  <div
                    class="col-9 d-flex align-items-center justify-content-center"
                  >
                    <p>XMR/BNB</p>
                  </div>
                </div>
              </div>
              <div
                class="col-2 info__text d-flex align-items-center justify-content-center"
              >
                <p>$ 20.00</p>
              </div>
              <div
                class="col-3 info__text2 d-flex align-items-center justify-content-center"
              >
                <p>$5.00</p>
              </div>
              <div
                class="col-4 button d-flex align-items-baseline justify-content-end"
              >
                <button class="me-3">View</button>
                <button>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="row">
      <div class="col">
        <div class="card mt-5 p-2 card_table">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h4 class="text-white">Balance details</h4>
              </div>
              <div class="row">
                <div class="col-lg-4 col-xl-6">
                  <input type="text" class="w-75 p-2" />
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <input type="checkbox" id="balance" />
                  <label class="ms-2 text-white" for="balance"
                    >Hide Small Balances</label
                  >
                </div>
                <div
                  class="col-lg-4 col-xl-3 mt-2 mt-lg-0 d-md-flex align-items-center justify-content-center"
                >
                  <p class="text-warning m-0 text-decoration-underline">
                    Convert Small Balance to BNB
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body d-flex d-md-block">
            <div class="row flex-column flex-md-row name_column">
              <div class="col">
                <p>Date</p>
              </div>
              <div class="col">
                <p>Coin</p>
              </div>
              <div class="col">
                <p>Total</p>
              </div>
              <div class="col">
                <p>Available</p>
              </div>
              <div class="col">
                <p>Type</p>
              </div>
              <div class="col">
                <p>Action</p>
              </div>
            </div>
            <div
              class="row mt-md-3 ms-3 ms-md-0 flex-column flex-md-row column-info"
            >
              <div class="col p-0">
                <p>1.04.2022</p>
              </div>
              <div class="col">
                <div class="row align-items-center">
                  <div class="col-3 d-none d-md-block">
                    <div class="avatar"></div>
                  </div>
                  <div class="col col-9">
                    <p>BTC</p>
                    <p class="text-under">BMB</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>0.00382689</p>
              </div>
              <div class="col">
                <p>0.00000000</p>
              </div>
              <div class="col">
                <p style="color: #0ECB81 !important">
                  Replenishment
                </p>
              </div>

              <div class="col">
                <p id="action-text">Buy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    chartOptionsBar: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 5,
      },
    },
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },

      // xaxis: {
      //   type: 'datetime',
      //   categories: [
      //     '2019-01-01',
      //     '2019-02-01',
      //     '2019-03-01',
      //     '2019-04-01',
      //     '2019-05-01',
      //     '2019-06-01',
      //     '2019-07-01',
      //   ],
      // },
    },
    series: [
      {
        name: 'WebsiteHits',
        data: [15, 715, 26, 1125, 60, 920, 93],
      },
    ],
  }),
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
